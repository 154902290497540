import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import { moduleName as authModuleName, setupPasswordAction } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { password as passwordRule } from '../../../../utils/inputRules';
import styles from '../index.module.less';

interface ISetupPassword {
  loading: boolean;
  setupPassword: (password: string) => Action;
}

const SetupPassword: React.FC<ISetupPassword> = ({ loading, setupPassword }) => (
  <div className={styles.container}>
    <Typography.Title className={styles.title}>Set up your password</Typography.Title>
    <Form layout="vertical" className={styles.form} onFinish={setupPassword} labelWrap>
      <Form.Item name="password" label="Password" rules={passwordRule}>
        <Input.Password size="large" />
      </Form.Item>
      <Form.Item
        name="passwordConfirm"
        label="Password Confirm"
        dependencies={['password']}
        rules={[
          ...passwordRule,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The new password that you entered do not mach!'));
            },
          }),
        ]}
      >
        <Input.Password size="large" />
      </Form.Item>
      <Form.Item noStyle>
        <Button type="primary" size="large" htmlType="submit" className={styles.authButton} loading={loading}>
          Sign Up
        </Button>
      </Form.Item>
    </Form>
  </div>
);

const mapStateToProps = (state: RootState) => ({
  loading: state[authModuleName].loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setupPassword: (password: string) => dispatch(setupPasswordAction(password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupPassword);
