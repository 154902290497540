import React, { PropsWithChildren, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { moduleName as authModuleName } from '../../../store/ducks/auth';
import { RootState } from '../../../store/reducers';
import { IUser } from '../../../types/entries';
import styles from './index.module.less';

interface IAuthLayout extends PropsWithChildren {
  authorized: boolean;
  isVerificationInProgress: boolean;
  user: IUser | null;
}

const Auth: React.FC<IAuthLayout> = ({ authorized, isVerificationInProgress, user, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.isVerified) {
      navigate('/auth/verify-email');

      return;
    }

    if (isVerificationInProgress) {
      navigate('/auth/verification/success');

      return;
    }

    if (user && !user.isPasswordExist) {
      navigate('/auth/setup-password');

      return;
    }

    if (authorized) {
      navigate('/');
    }
  }, [authorized, user, isVerificationInProgress]);

  return (
    <div className={styles.layout}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  authorized: state[authModuleName].authorized,
  isVerificationInProgress: state[authModuleName].isVerificationInProgress,
  user: state[authModuleName].user,
});

export default connect(mapStateToProps)(Auth);
