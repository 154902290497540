import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Button, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import { finishVerificationAction } from '../../../../store/ducks/auth';
import styles from '../index.module.less';

interface IVerifyEmail {
  finishVerification: () => Action;
}

const Verification: React.FC<IVerifyEmail> = ({ finishVerification }) => (
  <div className={styles.container}>
    <div className={styles.iconContainer}>
      <CheckCircleOutlinedIcon className={styles.icon} />
      <Typography.Title className={styles.title}>Email verified</Typography.Title>
    </div>
    <Typography.Text className={styles.description}>Click below to set your password</Typography.Text>

    <Button type="primary" size="large" onClick={finishVerification} className={styles.authButton}>
      Continue
    </Button>
  </div>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  finishVerification: () => dispatch(finishVerificationAction()),
});

export default connect(null, mapDispatchToProps)(Verification);
