import React, { useEffect, useState } from 'react';
import { Action, Dispatch } from 'redux';
import { UserOutlined } from '@ant-design/icons';
import { ChevronLeftOutlined, NotificationsOutlined } from '@mui/icons-material';
import { Avatar, Badge, Button, Dropdown, MenuProps, Typography } from 'antd';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import { moduleName as authModuleName, signOut as signOutAction } from '../../../store/ducks/auth';
import { RootState } from '../../../store/reducers';
import { IUser } from '../../../types/entries';
import Simple from '../Simple';
import MenuCommon from './Menu';
import styles from './index.module.less';

interface IMain {
  children: React.ReactNode;
  title?: string;
  extra?: React.ReactNode;
  withBackButton?: boolean;
  user: IUser | null;
  authorized: boolean;
  signOut: () => Action;
}

const Main: React.FC<IMain> = ({ children, title, extra, withBackButton, user, authorized, signOut }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [key, setKey] = useState('');

  useEffect(() => {
    setKey(pathname.split('/').find((item) => !!item) || '');
  }, [pathname]);
  const goBack = () => {
    navigate(-1);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={clsx(styles.menuItem, { [styles.active]: `/${key}` === '/profile' })}>
          <Link className={styles.menuLink} to="/profile">
            <span className={clsx('icon-person', styles.menuLinkIcon)} />
            <Typography className={styles.menuLinkText}>Profile</Typography>
          </Link>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={clsx(styles.menuItem, { [styles.active]: `/${key}` === '/profile' })}>
          <Link className={styles.menuLink} to="/plans">
            <span className={clsx('icon-credit_card', styles.menuLinkIcon)} />
            <Typography className={styles.menuLinkText}>Update plan</Typography>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Simple>
      {authorized && <MenuCommon />}
      <div className={styles.container}>
        {title && (
          <div className={styles.header}>
            <div className="flex-row gap-10 flex-align-center flex-justify-start">
              {withBackButton && (
                <Button type="text" icon={<ChevronLeftOutlined className="fs-40 color-primary" />} onClick={goBack} />
              )}
              <Typography.Title className={styles.title}>{title}</Typography.Title>
            </div>
            <div className="flex-row gap-12 flex-align-center flex-justify-start">
              {extra}
              {authorized && (
                <Button type="text" className={styles.notificationsButton}>
                  <Badge count={0} size="small" className={styles.notifications} showZero={false}>
                    <NotificationsOutlined />
                  </Badge>
                </Button>
              )}
              <div className="flex-column gap-4">
                <Typography.Text className={styles.username}>{`${user?.firstName} ${user?.lastName}`}</Typography.Text>
                <Button type="text" className={styles.logout} onClick={signOut}>
                  Log Out
                </Button>
              </div>
              <Dropdown
                overlayClassName={styles.dropdown}
                menu={{ items }}
                placement="bottomRight"
                arrow={false}
                trigger={['click']}
              >
                <Avatar size={60} icon={<UserOutlined />} src={user?.avatar} />
              </Dropdown>
            </div>
          </div>
        )}
        <div className={styles.contentWrapper}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </Simple>
  );
};

Main.defaultProps = {
  title: undefined,
  extra: undefined,
  withBackButton: false,
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
  authorized: state[authModuleName].authorized,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signOut: () => dispatch(signOutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
