import React from 'react';

import Content from '../../components/Pages/Profile';
import { displayName } from '../../config';
import Layout from '../../components/Layout/Main';

const Profile = (): JSX.Element => {
  document.title = `${displayName}: Profile`;

  return (
    <Layout title="Profile" withBackButton>
      <Content />
    </Layout>
  );
};

export default Profile;
