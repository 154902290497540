import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { Button, Typography } from 'antd';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import {
  moduleName as customerModuleName,
  getPaymentLink as getPaymentLinkAction,
  setPaymentLink as setPaymentLinkAction,
} from '../../../../store/ducks/customer';
import { RootState } from '../../../../store/reducers';
import { IPlan } from '../../../../types';
import { capitalizeFirstLetter } from '../../../../utils';
import { getCurrencySymbol } from '../../../../utils/text';
import styles from '../index.module.less';

interface IPlanCard {
  plan: IPlan;
  paymentLink: string | null;
  setPaymentLink: (paymentLink: string | null) => Action;
  getPaymentLink: (packageName: string) => Action;
}

const PlanCard: React.FC<IPlanCard> = ({ plan, paymentLink, getPaymentLink, setPaymentLink }) => {
  const handleGetPaymentLink = () => {
    getPaymentLink(plan.name);
  };

  useEffect(() => {
    if (!paymentLink) return;

    window.open(paymentLink, '_self', 'noreferrer');
    setPaymentLink(null);
  }, [paymentLink]);

  return (
    <div className={clsx(styles.cardContainer, { [styles.cardActive]: plan.isActive })}>
      <Typography className="color-white m-0 fs-24">{plan.name}</Typography>
      <Typography className="color-white fs-16">{plan.description}</Typography>
      <div className="flex-row flex-align-start gap-5">
        <Typography className="fs-32 fw-700 color-white m-0">
          {`${getCurrencySymbol(plan.currency)}${plan.price.toLocaleString()}`}
        </Typography>
        <Typography className="fs-16 color-white mt-8">/{capitalizeFirstLetter(plan.period)}</Typography>
      </div>
      <ul className="flex-column gap-8">
        {plan.features.map((feature) => (
          <li key={`${plan.paymentLinkId}-${feature.label}`} className="flex-row flex-align-center gap-8">
            <div className={styles.icon}>
              {feature.isEnabled ? <CheckRounded className="fs-16" /> : <CloseRounded className="fs-16" />}
            </div>
            <Typography className="fs-16 color-white">{feature.label}</Typography>
          </li>
        ))}
      </ul>
      <Button type={plan.isActive ? 'primary' : 'default'} className={styles.buyButton} onClick={handleGetPaymentLink}>
        Purchase
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  paymentLink: state[customerModuleName].paymentLink,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPaymentLink: (paymentLink: string | null) => dispatch(setPaymentLinkAction(paymentLink)),
  getPaymentLink: (packageName: string) => dispatch(getPaymentLinkAction(packageName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanCard);
