import { useGoogleLogin } from '@react-oauth/google';
import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import { ISignUp, moduleName as authModuleName, signUpAction } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { login, required } from '../../../../utils/inputRules';
import { IconGoogle, Logo } from '../../../Common/Icon';
import styles from '../index.module.less';

interface ISignUpComponent {
  loading: boolean;
  error: Error | null;
  signUp: (data: ISignUp) => Action;
}
const SignUp: React.FC<ISignUpComponent> = ({ loading, signUp }) => {
  const handleGoogleSignUp = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // eslint-disable-next-line
      console.log(tokenResponse);
    },
  });

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <Typography.Title className={styles.title}>Sign Up to Neural Voice</Typography.Title>

      <Button
        size="large"
        onClick={() => handleGoogleSignUp()}
        className={styles.authButton}
        loading={loading}
        icon={<IconGoogle />}
      >
        Sign up with Google
      </Button>
      <div className={styles.divider}>
        <div className={styles.line} />
        <Typography className={styles.text}>or</Typography>
        <div className={styles.line} />
      </div>
      <Form layout="vertical" className={styles.form} onFinish={signUp} labelWrap>
        <Form.Item name="firstName" label="First Name" rules={required}>
          <Input size="large" />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" rules={required}>
          <Input size="large" />
        </Form.Item>
        <Form.Item name="email" label="Business Email" rules={login}>
          <Input size="large" />
        </Form.Item>
        <Form.Item noStyle>
          <Button type="primary" size="large" htmlType="submit" className={styles.authButton} loading={loading}>
            Next
          </Button>
        </Form.Item>
      </Form>
      <div className={styles.footer}>
        <Typography className={styles.link}>
          Already have an account?{' '}
          <Link className={styles.signIn} to="/auth/sign-in">
            Sign In
          </Link>
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[authModuleName].loading,
  error: state[authModuleName].error,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signUp: (data: ISignUp) => dispatch(signUpAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
