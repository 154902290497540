import { CreditCardRounded, Group, Home } from '@mui/icons-material';
import React from 'react';

import AdminDashboard from '../pages/admin/dashboard';
import Characters from '../pages/customer/characters';
import CharacterDetails from '../pages/customer/characters/details';
import CharacterKnowledge from '../pages/customer/characters/knowledge';
import CustomerDashboard from '../pages/customer/dashboard';
import Records from '../pages/customer/records';
import RecordDetails from '../pages/customer/records/details';
import Payment from '../pages/payment';
import Confirmation from '../pages/payment/confirmatioln';
import { IRoute } from '../types';

const sideMenuRoutes: IRoute[] = [
  {
    bind: {
      path: '/home',
      element: <CustomerDashboard />,
    },
    privateRoute: true,
    name: 'Home',
    icon: <Home />,
  },
  {
    bind: {
      path: '/home',
      element: <AdminDashboard />,
    },
    privateRoute: true,
    adminRoute: true,
    name: 'Home',
    icon: <Home />,
  },
  // {
  //   bind: {
  //     path: '/analytics',
  //     element: <ComingSoon />,
  //   },
  //   name: 'Analytics',
  //   privateRoute: true,
  //   icon: <Leaderboard />,
  // },
  {
    bind: {
      path: '/payment',
      element: <Payment />,
    },
    name: 'Billing Plans',
    privateRoute: false,
    icon: <CreditCardRounded />,
    children: [
      {
        bind: {
          path: '/confirmation',
          element: <Confirmation />,
        },
        name: 'Payment',
        hidden: true,
      },
    ],
  },
  {
    bind: {
      path: '/characters',
      element: <Characters />,
    },
    name: 'Characters',
    privateRoute: true,
    icon: <Group />,
    children: [
      {
        bind: {
          path: '/:characterId',
          element: <CharacterDetails />,
        },
        name: 'Character Details',
        hidden: true,
        privateRoute: true,
      },
      {
        bind: {
          path: '/:characterId/knowledge',
          element: <CharacterKnowledge />,
        },
        name: 'Character Knowledge ',
        hidden: true,
        privateRoute: true,
      },
      {
        bind: {
          path: '/:characterId/records',
          element: <Records />,
        },
        name: 'Recordings',
        hidden: true,
        privateRoute: true,
      },
      {
        bind: {
          path: '/:characterId/records/:recordId',
          element: <RecordDetails />,
        },
        name: 'Recordings',
        hidden: true,
        privateRoute: true,
      },
    ],
  },
];

export default sideMenuRoutes;
