import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { alertDelayError, name as appName } from '../../config';
import { IUser } from '../../types/entries';
import { getDataInStorage } from '../../utils/storage';
import {
  CLIENT_EMAILS,
  CLIENT_FIRST_NAME_ARRAY,
  CLIENT_LAST_NAME_ARRAY,
  CLIENT_PASSWORDS,
  CUSTOMER_ID,
} from '../constants';
import { Action } from '../index';
import { RootState } from '../reducers';
import { FetchResponse, cancelableLocationSaga, defaultResponseProcessing } from './common';

/**
 * Constants
 * */

export const moduleName = 'auth';
const prefix = `${appName}/${moduleName}`;

export const START = `${prefix}/START`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const ERROR = `${prefix}/ERROR`;
export const ERROR_RESET = `${prefix}/ERROR_RESET`;

export const SIGN_IN = `${prefix}/SIGN_IN`;
export const SIGN_IN_BY_TOKEN = `${prefix}/SIGN_INSIGN_IN_BY_TOKEN`;
export const CHECK_IS_USER_PAID = `${prefix}/CHECK_IS_USER_PAID`;
export const SIGN_OUT = `${prefix}/SIGN_OUT`;
export const SIGN_UP = `${prefix}/SIGN_UP`;
export const VERIFICATION_BY_CODE = `${prefix}/VERIFICATION_BY_CODE`;
export const FINISH_VERIFICATION = `${prefix}/FINISH_VERIFICATION`;
export const VERIFICATION_BY_TOKEN = `${prefix}/VERIFICATION_BY_TOKEN`;
export const SETUP_PASSWORD = `${prefix}/SETUP_PASSWORD`;
export const FORGOT_PASSWORD = `${prefix}/FORGOT_PASSWORD`;
export const RESET_PASSWORD = `${prefix}/RESET_PASSWORD`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  error: Error | null;
  authorized: boolean;
  user: IUser | null;
  accessToken: string | null;
  refreshToken: string | null;
  isVerificationInProgress: boolean;
}

const localState: State = {
  loading: false,
  error: null,
  authorized: false,
  user: null,
  accessToken: null,
  refreshToken: null,
  isVerificationInProgress: false,
  ...getDataInStorage(moduleName),
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case START:
      return { ...state, loading: true, error: null };
    case SUCCESS:
      return { ...state, loading: false, ...payload };
    case ERROR:
      return { ...state, loading: false, error: payload };
    case ERROR_RESET:
      return { ...state, loading: false, error: null };

    case FINISH_VERIFICATION:
      return { ...state, isVerificationInProgress: false };

    case SIGN_OUT:
      return { ...state, authorized: false, user: null, accessToken: null, refreshToken: null };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */
export interface ISignIn {
  email: string;
  password: string;
}

export interface ISignUp {
  firstName: string;
  lastName: string;
  email: string;
}

/**
 * Action Creators
 * */
export const signIn = ({ email, password }: ISignIn): Action => ({
  type: SIGN_IN,
  payload: { email, password },
});

export const signInByToken = (token: string): Action => ({
  type: SIGN_IN_BY_TOKEN,
  payload: { token },
});

export const checkIsUserPaid = (): Action => ({
  type: CHECK_IS_USER_PAID,
});

export const signOut = (): Action => ({
  type: SIGN_OUT,
});

export const signUpAction = (payload: ISignUp): Action => ({
  type: SIGN_UP,
  payload,
});

export const verifyByCodeAction = (code: string): Action => ({
  type: VERIFICATION_BY_CODE,
  payload: { code },
});

export const finishVerificationAction = (): Action => ({
  type: FINISH_VERIFICATION,
});

export const verifyByTokenAction = (token: string): Action => ({
  type: VERIFICATION_BY_TOKEN,
  payload: { token },
});

export const setupPasswordAction = (password: string): Action => ({
  type: SETUP_PASSWORD,
  payload: { password },
});

export const resetPasswordAction = (password: string): Action => ({
  type: RESET_PASSWORD,
  payload: { password },
});

export const forgotPasswordAction = (email: string): Action => ({
  type: FORGOT_PASSWORD,
  payload: { email },
});

const getUser = ({ email, password }: ISignIn): IUser | undefined => {
  let user;

  CLIENT_EMAILS.forEach((login, index) => {
    if (email === login.trim() && CLIENT_PASSWORDS[index].trim() === password) {
      user = {
        id: CUSTOMER_ID,
        email: login.trim(),
        firstName: CLIENT_FIRST_NAME_ARRAY[index].trim(),
        lastName: CLIENT_LAST_NAME_ARRAY[index].trim(),
        isVerified: true,
        isPasswordExist: true,
      };
    }
  });

  return user;
};

/**
 * Sagas
 */
export function* signInSaga({ payload }: { payload: ISignIn }): Generator {
  yield put({
    type: START,
  });

  yield delay(2000);

  const user = getUser(payload);

  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
  const isUserPaid = false;

  if (user) {
    yield put({
      type: SUCCESS,
      payload: {
        accessToken,
        authorized: accessToken && isUserPaid,
        user,
      },
    });
  } else {
    yield put({
      type: ERROR,
      payload: { message: 'Incorrect login or password!' },
    });
  }
}

export function* signInByTokenSaga({ payload: { token } }: { payload: { token: string } }): Generator {
  yield put({
    type: START,
  });

  const response = (yield call(
    fetchAuthSaga,
    'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
    {},
    {
      token,
    },
  )) as FetchResponse;

  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
  const isUserPaid = true;

  yield defaultResponseProcessing(response, SUCCESS, ERROR, false, (data) => ({
    accessToken,
    authorized: accessToken && isUserPaid,
    user: {
      id: CUSTOMER_ID,
      email: data.email,
      firstName: data.given_name,
      lastName: data.family_name,
      avatar: data.picture,
      isVerified: true,
      isPasswordExist: true,
    },
  }));
}

export function* checkIsUserPaidSaga(): Generator {
  yield put({
    type: START,
  });

  const isUserPaid = true;
  const accessToken = yield select((state: RootState) => state[moduleName].accessToken);

  yield put({
    type: SUCCESS,
    payload: {
      authorized: accessToken && isUserPaid,
    },
  });
}

export function* signUpSaga({ payload: user }: { payload: ISignUp }): Generator {
  yield put({
    type: START,
  });

  yield delay(2000);

  yield put({
    type: SUCCESS,
    payload: {
      accessToken: process.env.REACT_APP_ACCESS_TOKEN,
      user,
    },
  });
}

export function* verifyByCodeSaga({ payload: { code } }: { payload: { code: string } }): Generator {
  yield put({
    type: START,
  });

  const user = yield select((state: RootState) => state[moduleName].user);

  // TODO Replace by API call
  yield delay(2000);

  if (code === '1111') {
    yield put({
      type: SUCCESS,
      payload: {
        isVerificationInProgress: true,
        user: {
          ...user,
          isVerified: true,
        },
      },
    });
  } else {
    yield put({
      type: ERROR,
      payload: 'Code is invalid',
    });
  }
}

export function* verifyByTokenSaga({ payload: { token } }: { payload: { token: string } }): Generator {
  yield put({
    type: START,
  });

  // TODO add API call
  yield delay(2000);
  // eslint-disable-next-line no-console
  console.log(token);

  const user = yield select((state: RootState) => state[moduleName].user);

  yield put({
    type: SUCCESS,
    payload: {
      user: {
        ...user,
        isVerified: true,
      },
    },
  });
}

export function* setupPasswordSaga({ payload: { password } }: { payload: { password: string } }): Generator {
  yield put({
    type: START,
  });

  const user = yield select((state: RootState) => state[moduleName].user);

  // TODO add API call
  yield delay(2000);
  // eslint-disable-next-line no-console
  console.log(password);

  yield put({
    type: SUCCESS,
    payload: {
      user: {
        ...user,
        isPasswordExist: true,
      },
    },
  });
}

export function* resetPasswordSaga({ payload: { password } }: { payload: { password: string } }): Generator {
  yield put({
    type: START,
  });

  // TODO add API call
  yield delay(2000);
  // eslint-disable-next-line no-console
  console.log(password);

  yield put({
    type: SUCCESS,
  });
}

export function* forgotPasswordSaga({ payload: { email } }: { payload: { email: string } }): Generator {
  yield put({
    type: START,
  });

  // TODO add API call
  yield delay(2000);
  // eslint-disable-next-line no-console
  console.log(email);

  yield put({
    type: SUCCESS,
  });
}

export function* updateStorageSaga(): Generator {
  yield delay(100);

  const data = yield select((state: RootState) => state[moduleName]);

  localStorage.setItem(moduleName, JSON.stringify(data));
}

/**
 * @param {String} url
 * @param {Object} init
 * @param {Object} options
 *
 * @returns {IterableIterator<Promise<Response>*>}
 */
export function* fetchAuthSaga(
  url: string,
  init: RequestInit = {},
  options: { [key: string]: boolean | string } = {},
): Generator {
  const { accessToken } = (yield select((state: RootState) => state[moduleName])) as State;
  const newInit = {
    credentials: 'same-origin',
    ...init,
  };

  newInit.headers = {
    Accept: 'application/json',
    ...(newInit.headers || {}),
  };

  if ((options?.token || accessToken) && options?.authorization !== false) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newInit.headers.Authorization = `Bearer ${options?.token || accessToken}`;
  }

  return yield fetch(url, newInit as RequestInit);
}

export function* saga(): Generator {
  yield takeLatest(SIGN_IN, cancelableLocationSaga.bind(null, signInSaga, ERROR, false));
  yield takeLatest(SIGN_IN_BY_TOKEN, cancelableLocationSaga.bind(null, signInByTokenSaga, ERROR, false));
  yield takeLatest(CHECK_IS_USER_PAID, cancelableLocationSaga.bind(null, checkIsUserPaidSaga, ERROR, false));
  yield takeLatest(SIGN_UP, cancelableLocationSaga.bind(null, signUpSaga, ERROR, false));
  yield takeLatest(VERIFICATION_BY_CODE, cancelableLocationSaga.bind(null, verifyByCodeSaga, ERROR, false));
  yield takeLatest(VERIFICATION_BY_TOKEN, cancelableLocationSaga.bind(null, verifyByTokenSaga, ERROR, false));
  yield takeLatest(SETUP_PASSWORD, cancelableLocationSaga.bind(null, setupPasswordSaga, ERROR, false));
  yield takeLatest(FORGOT_PASSWORD, cancelableLocationSaga.bind(null, forgotPasswordSaga, ERROR, false));
  yield takeLatest(RESET_PASSWORD, cancelableLocationSaga.bind(null, resetPasswordSaga, ERROR, false));
  yield takeLatest(ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: ERROR_RESET,
    });
  });

  yield takeLatest([SUCCESS, SIGN_OUT], updateStorageSaga);
}
