import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ProColumns } from '@ant-design/pro-table';
import { Button, Popover, Typography } from 'antd';
import moment from 'moment-timezone';

import { RootState } from '../../../../store/reducers';
import {
  moduleName as customerModuleName,
  getCustomerInvoices as getCustomerInvoicesAction,
  IGetInvoicesParams,
} from '../../../../store/ducks/customer';
import { JsonResult } from '../../../../store/ducks/common';
import { Action } from '../../../../store';
import ContentCard from '../../../Common/ContentCard';
import Table from '../../../Common/Table';
import { pageSize } from '../../../../config';
import { getCurrencySymbol } from '../../../../utils/text';
import { capitalizeFirstLetter } from '../../../../utils';
import Tag from '../../../Common/Tag';

import styles from '../index.module.less';

interface IInvoices {
  loading: boolean;
  invoices: JsonResult[] | null;
  getCustomerInvoices: (params: IGetInvoicesParams) => Action;
  total: number;
}

const Invoices: React.FC<IInvoices> = ({ loading, invoices, getCustomerInvoices, total }) => {
  const [params, setParams] = useState<IGetInvoicesParams>({});
  const [page, setPage] = useState<number>(1);
  const maxPage = Math.ceil(total / pageSize);

  useEffect(() => {
    getCustomerInvoices(params);
  }, [params]);

  const handlePrevPage = () => {
    setParams({
      limit: pageSize,
      ending_before: invoices && page ? (invoices[0].id as string) : undefined,
    });
    setPage((prev) => (prev - 1 ? prev - 1 : 1));
  };

  const handleNextPage = () => {
    setParams({
      limit: pageSize,
      starting_after: invoices && page ? (invoices[invoices.length - 1].id as string) : undefined,
    });
    setPage((prev) => (prev + 1 <= maxPage ? prev + 1 : maxPage));
  };

  const getTagType = (status: string) => {
    switch (status) {
      case 'paid':
        return 'positive';
      default:
        return 'draft';
    }
  };

  const columns: ProColumns<JsonResult>[] = [
    {
      title: 'Amount',
      width: 100,
      align: 'right',
      dataIndex: 'total',
      render: (_, row) => (
        <Typography.Title level={5} className="color-white m-0">
          {`${getCurrencySymbol(row.currency)} ${row.total / 100}`}
        </Typography.Title>
      ),
    },
    {
      title: '',
      width: 50,
      align: 'left',
      dataIndex: 'currency',
      renderText: (currency) => <Typography className="color-white">{currency?.toUpperCase()}</Typography>,
    },
    {
      title: 'Status',
      width: 100,
      dataIndex: 'status',
      align: 'center',
      renderText: (status) => (
        <div className="flex-row flex-justify-center">
          <Tag style={{ borderRadius: 4 }} label={capitalizeFirstLetter(status)} type={getTagType(status)} />
        </div>
      ),
    },
    {
      title: 'Invoice number',
      width: 150,
      render: (_, row) => (
        <Button type="text" href={row.hosted_invoice_url} target="_blank">
          <Typography className="color-aurichalcite">{row.number}</Typography>
        </Button>
      ),
    },
    {
      title: 'Customer email',
      width: 200,
      dataIndex: 'customer_email',
      renderText: (email) => <Typography className="color-white">{email}</Typography>,
    },
    {
      title: 'Due',
      width: 80,
      dataIndex: 'expires_at',
      renderText: (expires_at) => (
        <Typography className="color-white">{moment(expires_at).format('DD MMM')}</Typography>
      ),
    },
    {
      title: 'Created',
      width: 120,
      dataIndex: 'created',
      renderText: (created) => (
        <Typography className="color-white">{moment(created).format('DD MMM, HH:MM')}</Typography>
      ),
    },
    {
      title: '',
      render: (_, row) => (
        <div className="flex-row align-center flex-justify-end">
          <Popover content="Download PDF">
            <Button
              href={row.invoice_pdf}
              download
              type="text"
              className={styles.actionButton}
              icon={<span className="icon-file_download" />}
            />
          </Popover>
        </div>
      ),
    },
  ];

  return (
    <ContentCard title="Invoices" align="left">
      <div className="w-full flex-column gap-20">
        <Table<JsonResult>
          dataSource={invoices || []}
          columns={columns}
          toolBarRender={false}
          search={false}
          pagination={false}
          loading={loading}
        />
        {invoices && maxPage > 1 && (
          <div className="flex-row flex-justify-center gap-20">
            <Button className={styles.paginationButton} type="text" onClick={handlePrevPage} disabled={page === 1}>
              Prev
            </Button>
            <Button
              className={styles.paginationButton}
              type="text"
              onClick={handleNextPage}
              disabled={page === maxPage}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </ContentCard>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[customerModuleName].loading,
  invoices: state[customerModuleName].invoices,
  total: state[customerModuleName].total,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCustomerInvoices: (params: IGetInvoicesParams) => dispatch(getCustomerInvoicesAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
