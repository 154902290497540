import React from 'react';

import Content from '../../../components/Pages/Auth/VerifyEmail';
import { displayName } from '../../../config';
import Layout from '../../../components/Layout/Auth';

const VerifyEmail = (): JSX.Element => {
  document.title = `${displayName}: Verify Email`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default VerifyEmail;
