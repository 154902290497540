import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';

import PlanCard from './PlanCard';
import { RootState } from '../../../store/reducers';
import { moduleName as customerModuleName, getPlans as getPlansAction } from '../../../store/ducks/customer';
import { moduleName as authModuleName } from '../../../store/ducks/auth';
import { IPlan } from '../../../types';
import { Action } from '../../../store';

import styles from './index.module.less';
import Loading from '../../Common/Loading';

interface IPlans {
  plans: IPlan[] | null;
  loading: boolean;
  getPlans: () => Action;
  authorized: boolean;
  accessToken: string | null;
}

const Plans = ({ plans, loading, getPlans, authorized, accessToken }: IPlans) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!authorized && !accessToken) {
      navigate('/sign-in');
    }
  }, [authorized, accessToken]);

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <>
      <Loading
        visible={!!loading}
        absolute
        className="z-index-100 background-transparent backdrop-filter-blur-10 border-radius-16"
      />
      <div className={styles.cardsWrapper}>
        {plans?.map((plan) => <PlanCard key={plan.paymentLinkId} plan={plan} />)}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  plans: state[customerModuleName].plans,
  loading: state[customerModuleName].loading,
  accessToken: state[authModuleName].accessToken,
  authorized: state[authModuleName].authorized,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPlans: () => dispatch(getPlansAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
