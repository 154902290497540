import { IPlan } from '../types';

export const CUSTOMER_ID = process.env.REACT_APP_CUSTOMER_ID;
export const CLIENT_EMAILS = process.env.REACT_APP_CLIENT_EMAIL?.split(',') || [];
export const CLIENT_PASSWORDS = process.env.REACT_APP_CLIENT_PASSWORD?.split(',') || [];

export const CLIENT_FIRST_NAME_ARRAY = process.env.REACT_APP_CLIENT_FIRST_NAME?.split(',') || [];
export const CLIENT_LAST_NAME_ARRAY = process.env.REACT_APP_CLIENT_LAST_NAME?.split(',') || [];

export const PLANS: IPlan[] = [
  {
    name: 'Standard',
    description: 'Basic AI voice features ideal for startup web and phone use',
    price: 400,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '1,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: false,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: false,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
    paymentLinkId: 'test_8wMdQU76S2RU436aEG',
  },
  {
    name: 'Professional',
    description: 'Expanded AI integration and features for involving business needs',
    price: 1650,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '5,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: true,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: true,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
    isActive: true,
    paymentLinkId: 'test_9AQ0041My502aruaEF',
  },
  {
    name: 'Enterprise',
    description: 'Comprehensive AI solution with full support for large enterprises',
    price: 3200,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '5,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: true,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: true,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: true,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: true,
      },
    ],
    paymentLinkId: 'test_4gw4gkaj4dwy7fi144',
  },
];
