import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import { verifyByTokenAction } from '../../../../store/ducks/auth';
import Loading from '../../../Common/Loading';
import styles from '../index.module.less';

interface IVerifyEmail {
  verifyEmail: (token: string) => Action;
}

const Verification: React.FC<IVerifyEmail> = ({ verifyEmail }) => {
  const { token } = useParams();

  useEffect(() => {
    if (!token) return;

    verifyEmail(token);
  }, [token]);

  return (
    <div className={styles.container}>
      <Loading visible />
      <Typography className={styles.description}>Verification in progress</Typography>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  verifyEmail: (token: string) => dispatch(verifyByTokenAction(token)),
});

export default connect(null, mapDispatchToProps)(Verification);
