import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { alertDelayError, name as appName } from '../../config';
import { Action } from '../index';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse } from './common';
import { fetchAuthSaga } from './auth';
import { JsonResult } from '../../types';

/**
 * Constants
 * */
export const moduleName = 'client';
const prefix = `${appName}/${moduleName}`;

export const CLIENT_START = `${prefix}/CLIENT_START`;
export const CLIENT_SUCCESS = `${prefix}/CLIENT_SUCCESS`;
export const CLIENT_ERROR = `${prefix}/CLIENT_ERROR`;
export const CLIENT_ERROR_RESET = `${prefix}/CLIENT_ERROR_RESET`;

export const GET_CLIENTS_LIST = `${prefix}/GET_CLIENTS_LIST`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  error: Error | null;
  clients: JsonResult[] | null;
}

const localState: State = {
  loading: false,
  error: null,
  clients: null,
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_START:
      return { ...state, loading: true, error: null };
    case CLIENT_SUCCESS:
      return { ...state, loading: false, ...payload };
    case CLIENT_ERROR:
      return { ...state, loading: false, error: payload };
    case CLIENT_ERROR_RESET:
      return { ...state, loading: false, error: null };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */

/**
 * Action Creators
 * */

export const getClientsList = (): Action => ({
  type: GET_CLIENTS_LIST,
});

/**
 * Sagas
 */
export function* getClientsListSaga(): Generator {
  yield put({
    type: CLIENT_START,
  });

  const response = (yield call(
    fetchAuthSaga,
    `${process.env.REACT_APP_BILLING_API_URL}/database/clients`,
  )) as FetchResponse;

  yield defaultResponseProcessing(response, CLIENT_SUCCESS, CLIENT_ERROR, false, (data) => {
    // eslint-disable-next-line no-console
    console.log(data);

    return {
      clients: [],
    };
  });
}

export function* saga(): Generator {
  yield takeLatest(GET_CLIENTS_LIST, cancelableLocationSaga.bind(null, getClientsListSaga, CLIENT_ERROR, false));

  yield takeLatest(CLIENT_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: CLIENT_ERROR_RESET,
    });
  });
}
