import { call, delay, put, takeLatest } from 'redux-saga/effects';

import qs from 'qs';
import { alertDelayError, name as appName } from '../../config';
import { Action } from '../index';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse, JsonResult } from './common';
import { IPackage, IPeriod } from '../../types/customer';
import { fetchAuthSaga } from './auth';
import { CUSTOMER_ID, PLANS } from '../constants';
import { IPlan } from '../../types';

/**
 * Constants
 * */
export const moduleName = 'customer';
const prefix = `${appName}/${moduleName}`;

export const CUSTOMER_START = `${prefix}/CUSTOMER_START`;
export const CUSTOMER_SUCCESS = `${prefix}/CUSTOMER_SUCCESS`;
export const CUSTOMER_ERROR = `${prefix}/CUSTOMER_ERROR`;
export const CUSTOMER_ERROR_RESET = `${prefix}/CUSTOMER_ERROR_RESET`;

export const GET_CUSTOMER_PERIOD = `${prefix}/GET_CUSTOMER_PERIOD`;
export const GET_CUSTOMER_INVOICES = `${prefix}/GET_CUSTOMER_INVOICES`;
export const GET_PLANS = `${prefix}/GET_PLANS`;
export const GET_PAYMENT_LINK = `${prefix}/GET_PAYMENT_LINK`;
export const SET_PAYMENT_LINK = `${prefix}/SET_PAYMENT_LINK`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  error: Error | null;
  period: IPeriod | null;
  package: IPackage | null;
  invoices: JsonResult[] | null;
  total: number;
  plans: IPlan[] | null;
  paymentLink: string | null;
}

const localState: State = {
  loading: false,
  error: null,
  period: null,
  package: null,
  invoices: null,
  total: 0,
  plans: PLANS,
  paymentLink: null,
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_START:
      return { ...state, loading: true, error: null };
    case CUSTOMER_SUCCESS:
      return { ...state, loading: false, ...payload };
    case CUSTOMER_ERROR:
      return { ...state, loading: false, error: payload };
    case CUSTOMER_ERROR_RESET:
      return { ...state, loading: false, error: null };
    case SET_PAYMENT_LINK:
      return { ...state, ...payload };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */

/**
 * Action Creators
 * */

export interface ICustomerParams {
  customerId: number;
  periodId?: number;
}
export interface ICustomerPayload {
  payload: ICustomerParams;
}

export interface IGetInvoicesParams {
  limit?: number;
  starting_after?: string;
  ending_before?: string;
}

export const getCustomerPeriod = (payload: ICustomerParams): Action => ({
  type: GET_CUSTOMER_PERIOD,
  payload,
});

export const getCustomerInvoices = (payload: IGetInvoicesParams): Action => ({
  type: GET_CUSTOMER_INVOICES,
  payload,
});

export const getPlans = (): Action => ({
  type: GET_PLANS,
});

export const getPaymentLink = (packageName: string): Action => ({
  type: GET_PAYMENT_LINK,
  payload: { packageName },
});

export const setPaymentLink = (paymentLink: string | null): Action => ({
  type: SET_PAYMENT_LINK,
  payload: { paymentLink },
});

/**
 * Sagas
 */
export function* getCustomerPeriodSaga({ payload: { customerId, periodId } }: ICustomerPayload): Generator {
  yield put({
    type: CUSTOMER_START,
  });

  const response = (yield call(
    fetchAuthSaga,
    `${process.env.REACT_APP_BILLING_API_URL}/app/client/${customerId}/period${periodId ? `/${periodId}` : ''}`,
  )) as FetchResponse;

  yield defaultResponseProcessing(response, CUSTOMER_SUCCESS, CUSTOMER_ERROR, false, (period) => ({ period }));
}

export function* getCustomerInvoicesSaga({ payload }: { payload: IGetInvoicesParams }): Generator {
  yield put({
    type: CUSTOMER_START,
  });

  const params = qs.stringify(payload);

  const response = (yield call(
    fetchAuthSaga,
    `https://api.stripe.com/v1/invoices?expand[0]=total_count&${params}`,
    {},
    { token: process.env.REACT_APP_STRIPE_SECRET_ID || '' },
  )) as FetchResponse;

  yield defaultResponseProcessing(response, CUSTOMER_SUCCESS, CUSTOMER_ERROR, false, (data) => ({
    invoices: data.data,
    total: data.total_count,
  }));

  // yield put({
  //   type: CUSTOMER_SUCCESS,
  //   payload: { invoices: INVOICES.map((invoice) => invoice.object), total: INVOICES.length },
  // });
}

export function* getPlansSaga(): Generator {
  yield put({
    type: CUSTOMER_START,
  });

  yield delay(2000);

  yield put({
    type: CUSTOMER_SUCCESS,
    payload: { plans: PLANS },
  });
}

export function* getPaymentLinkSaga({ payload: { packageName } }: { payload: { packageName: string } }): Generator {
  yield put({
    type: CUSTOMER_START,
  });

  const response = (yield call(fetchAuthSaga, `${process.env.REACT_APP_BILLING_API_URL}/generate-checkout`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ package_name: packageName, customer_id: CUSTOMER_ID }),
  })) as FetchResponse;

  yield defaultResponseProcessing(response, CUSTOMER_SUCCESS, CUSTOMER_ERROR, false, (data) => ({
    paymentLink: data,
  }));
}

export function* saga(): Generator {
  yield takeLatest(
    GET_CUSTOMER_PERIOD,
    cancelableLocationSaga.bind(null, getCustomerPeriodSaga, CUSTOMER_ERROR, false),
  );
  yield takeLatest(
    GET_CUSTOMER_INVOICES,
    cancelableLocationSaga.bind(null, getCustomerInvoicesSaga, CUSTOMER_ERROR, false),
  );
  yield takeLatest(GET_PLANS, cancelableLocationSaga.bind(null, getPlansSaga, CUSTOMER_ERROR, false));
  yield takeLatest(GET_PAYMENT_LINK, cancelableLocationSaga.bind(null, getPaymentLinkSaga, CUSTOMER_ERROR, false));

  yield takeLatest(CUSTOMER_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: CUSTOMER_ERROR_RESET,
    });
  });
}
